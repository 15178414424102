import "./App.css";
import { useState } from "react";
import { generateImage } from "./api";
import { Logo } from "./logo";
import Countdown from "react-countdown";

const App = () => {
  const [images, setImages] = useState([]);
  const [prompt, setPrompt] = useState(null);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(null);

  const opeanAiGenerateImage = async () => {
    const res = await generateImage(prompt);
    setImages(res.data.data);
    setLoading(false);
  };

  const handleKeyPress = (ev) => {
    if (ev.keyCode === 13) {
      setLoading(true);
      setTimer(Date.now() + 30000);
      opeanAiGenerateImage();
    }
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      setTimer(null);
    } else {
      return <b>{seconds}</b>;
    }
  };

  return (
    <div
      className="App"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={Logo} alt="thecove logo" />
      <div style={{ position: "relative" }}>
        <input
          disabled={!!timer}
          onChange={(ev) => setPrompt(ev.target.value)}
          onKeyUp={handleKeyPress}
          value={prompt}
          placeholder="Cosa vuoi disegnare? (in inglese)"
          className="searchbar"
        ></input>
        {!!timer && (
          <div style={{ position: "absolute", top: 10, right: 16 }}>
            <Countdown date={timer} renderer={renderer} />
          </div>
        )}
      </div>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            margin: "0 10vw",
            gap: "1rem",
            justifyContent: "center",
          }}
        >
          {images.map((image) => (
            <div
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                padding: "1rem",
                borderRadius: "1rem",
                maxWidth: "85vw",
              }}
            >
              <img src={image.url} alt={prompt} className="image" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default App;
