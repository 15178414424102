import { Configuration, OpenAIApi } from "openai";
import env from "react-dotenv";

const configuration = new Configuration({
  apiKey: env.OPENAI_API_KEY,
});

const openai = new OpenAIApi(configuration);

export const generateImage = async (prompt) => {
  const response = await openai.createImage({
    prompt,
    n: 2,
    size: "512x512",
  });

  return response;
};
